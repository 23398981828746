.conteiner {
    height: 90vh;
    background-color: #303030;
    display: flex;
}

.myPhotoConteiner {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.myPhotoConteiner div {
    transform: rotate(110deg);
    background-color: rgb(133, 133, 133);
    display: flex;
    justify-content: center;
    width: 60%;
    align-items: center;
}

.myPhotoConteiner img {
    width: 100%;
    transform: rotate(250deg);
    border: 2px solid white;
}

.aboutConteiner {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    color: black;
    background-color: white;
}

.aboutConteiner h1 {
    margin-top: 10%;
    margin-bottom: 2%;
    font-size: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.aboutConteiner h2 {
    font-weight: 700;
    color: #646464;
    max-width: 90%;
}

.aboutConteiner span {
    font-weight: 700;
    color: #003cff
}

.aboutConteiner p {
    max-width: 80%;
    margin-top: 8%;
    font-size: 18px;
    font-weight: 700;
}

.aboutConteiner button {
    margin-top: 8%;
    margin-right: 1rem;
    width: 15%;
    padding: .5rem;
    font-size: 18px;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: 500;
    background-color: rgb(0, 53, 151);
}

.aboutConteiner button:hover {
    transition: .3s;
    background-color: rgb(0, 89, 255);
}

@media screen and (max-width: 1000px) {
    .conteiner {
        display: block;
        height: auto;
        margin-top: 20%;
    }
    .myPhotoConteiner {
        width: 100%;
        height: 50%;
    }
    .aboutConteiner {
        width: 100%;
        height: 50%;
        align-items: center;
        text-align: center;
    }
    .aboutConteiner button {
        width: auto;
        margin: .6rem;
        margin-top: 10%;
    }
  }

