.footer {
    min-height: 10rem;
    height: auto;
    display: flex;
    justify-content: space-between;
    background-color: black;
}

.webmemes {
    margin: 2rem 2rem;
    font-weight: 400;
    color: white;
}

.webmemes h3 {
    color: rgb(255, 255, 255);
}

.webmemes p {
    margin: .2rem 0;
    max-width: 30rem;
    color: rgb(170, 170, 170);
}


@media only screen and (max-width: 600px) {
    .footer {
      display: block;
    }
    .webmemes {
        margin: 0 1rem ;
        padding-top: 1rem;
        padding-bottom: .5rem;
    }
    .webmemes h3 {
        font-size: 16px;
    }
    .webmemes p{
        margin: none;
        max-width: 100%;
    }
  }