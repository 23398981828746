.conteiner {
    height: 90vh;
    background-image: url('../Img/background.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infsConteiner {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infsConteiner img {
    position: absolute;
    width: 30%;
}

.infsConteiner h1 {
    font-size: 70px;
    font-weight: 900;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
}

.infsConteiner h3 {
    font-size: 30px;
    font-weight: 500;
    margin-top: .8rem;
}

.infsConteiner button {
    margin-top:  1.4rem;
    padding: .5rem 1.8rem;
    font-size: 18px;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: 500;
    background-color: rgb(0, 64, 184);
}

.infsConteiner button:hover {
    transition: .3s;
    background-color: rgb(0, 89, 255)
}

@media screen and (max-width: 700px) {
    .infsConteiner h1{
        font-size: 26px;
    }
    .infsConteiner h3{
        font-size: 25px;
    }
    .infsConteiner button {
        margin-top:  1.4rem;
        padding: .3rem 1.4rem;
        font-size: 16px;
        border: none;
        cursor: pointer;
        color: white;
        font-weight: 500;
        background-color: rgb(0, 64, 184);
    }
  }

