.conteiner {
    width: 100%;
    background-color: #222222;
    color: rgb(199, 199, 199);
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: hideElement 200ms normal both;
    overflow: hidden;
    white-space: nowrap;
}

.conteiner span {
    margin: .8rem;
    font-size: 20px;
    font-weight: 400;
}

.conteinerContact {
    position: absolute;
    z-index: 9999;
    right: 0;
    top: 8rem;
}

.conteinerContact span {
    font-size: 30px;
}

@keyframes hideElement {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
  }