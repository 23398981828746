.conteiner {
    margin-top: 5rem;
    min-height: 100vh;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tittle {
    color: black;
    font-size: 40px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.conteiner h2 {
    font-weight: 700;
    color: #646464;
    max-width: 50%;
}

.conteiner div {
    display: flex;
    width: 90%;
    margin-top: 2rem;
}



@media screen and (max-width: 2000px) {
    .carousel {
        height: 80%;
    }
}


@media screen and (max-width: 1000px) {
    .conteiner div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 70%;
        width: 80%;
    }

    .conteiner h2 {
        font-weight: 700;
        color: #646464;
        max-width: 90%;
    }
}
