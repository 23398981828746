@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.conteiner {
    margin: .6rem;
    height: 45rem;
    background-color: white;
    box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 2px 7px 4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 7px 4px rgba(0, 0, 0, 0.363);
    padding: .8rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.conteiner img {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid grey;
}

.conteiner p {
    margin-top: 5%;
    color: grey;
    font-weight: 400;
    font-size: 20px;
}

.infsConteiner {
    display: flex;
    flex-direction: column;
}

.imgConteiner {
    display: flex;
    height: 15rem;
    width: 100%;
    align-items: center;
}

.textConteiner {
    display: flex;
    flex-direction: column;
    height: 30rem;
    width: 100%;
}

.conteiner h1 {
    font-size: 28px;
    color: #003cff
}
