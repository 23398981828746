.conteiner {
    height: 100vh;
    width: 20rem;
    background-color: white;
    animation: hideElement 500ms normal;
    overflow: hidden;
    white-space: nowrap;
    z-index: 9999;
}

.xmarkConteiner {
    display: flex;
    justify-content: end;
    font-size: 30px;
}

.xmarkConteiner span {
    margin: .8rem;
    cursor: pointer;
    color: rgb(174, 0, 255);
}

.infsConteiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    height: 40%;
}

.infs {
    border: 2px solid rgb(174, 0, 255);
    padding: 1rem .6rem;
}

.infs p {
    color: rgb(174, 0, 255);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: .5rem;
}

.infs a {
    color: black;
    text-decoration: none;
}

.icons {
    font-size: 26px;
    color: rgb(174, 0, 255);
    cursor: pointer;
}


@keyframes hideElement {
    from {
        width: 0;
    }
    to {
        width: -100%;
    }
  }