body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animation {
  animation: blickCursor 500ms infinite normal, typing 2.5s steps(30) 1s normal;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;

}

.hide {
  display: none;
}

@keyframes blickCursor{
  from {
      border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
      border-right-color: transparent;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #1100ff #303030;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #303030;
}

*::-webkit-scrollbar-track:hover {
  background-color: #303030;
}

*::-webkit-scrollbar-track:active {
  background-color: 303030;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #AE00FF;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #AE00FF;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #AE00FF;
}