.conteiner {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.boxConteiner {
    background-color: blue;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    width: 90%;
    height: 90%;
    border-radius: 4rem;
    display: flex;
}

.imgConteiner {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.imgConteiner img {
    width: 85%;
}

.formConteiner {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formConteiner form {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 70%;
    justify-content: center;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.75);
    padding: 2rem;
    border-radius: 1rem;
    text-align: center;
}

.formConteiner h1 {
    margin-bottom: .2rem;
    font-weight: 700;
    font-size: 30PX;
    color: white;
}

.formConteiner p {
    margin-bottom: 1.4rem;
    font-weight: 700;
    font-size: 14PX;
    color: rgb(212, 212, 212);
}

.formConteiner span {
    color: rgb(255, 217, 0);
}

.formConteiner input {
    margin-top: .1rem;
    margin-bottom: .1rem;
    padding: .7rem;
    outline: none;
}

.formConteiner select {
    margin-top: .1rem;
    margin-bottom: .1rem;
    padding: .7rem;
    outline: none;
    color: rgb(105, 105, 105);
    font-family: 'Courier New', Courier, monospace;
}

.formConteiner button {
    padding: .5rem 1.8rem;
    margin-top: .5rem;
    font-size: 18px;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: 500;
    background-color: rgb(0, 64, 184);
    
}

.formConteiner button:hover {
    transition: .3s;
    background-color: rgb(0, 89, 255)
}

.textAreaInput {
    padding: .7rem;
    height: 6rem;
    outline: none;
}

.textAreaInput::placeholder {
    font-family: 'Courier New', Courier, monospace;
}

.formConteiner input::placeholder {
    font-family: 'Courier New', Courier, monospace;
}

@media screen and (max-width: 1240px) {
    .imgConteiner {
        display: none;
    }
    .formConteiner {
        width: 100%;
    }

    .formConteiner h1 {
        margin-bottom: .2rem;
        font-weight: 700;
        font-size: 22PX;
        color: white;
    }
    
    .formConteiner p {
        margin-bottom: 2rem;
        font-weight: 700;
        font-size: 10PX;
        color: rgb(212, 212, 212);
    }
}